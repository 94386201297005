import Home from './routes/Index.svelte';

import SSE from './routes/Sse.svelte'
import Socket from './routes/socket.svelte'

export default [
    {
        path: '/',
        component: Home
    },
    {
        path : '/sse',
        component : SSE
    },
    {
        path : "/socket",
        component : Socket
    }
   

];


