<script>
  //key transition example
  //https://svelte.dev/tutorial/key-blocks

  //to save everything from memory to indexdb, you can use onbeforeunload event to let the user confirm
  // if he/she wants to close the window while the data is saved to indexdb
  // link to an example
  //https://www.w3schools.com/jsref/event_onbeforeunload.asp

  //==============================================================//
  let page = null;
  let params = {};

  routes.forEach((route) => {
    // Loop around all of the routes and create a new instance of
    // router for each one with some rudimentary checks.

    router(
      route.path,

      // Set the params variable to the context.
      // We use this on the component initialisation

      (ctx, next) => {
        params = { ...ctx.params };
        next();
      },

      // Check if auth is valid. If so, set the page to the component
      // otherwise redirect to login.

      () => {
        if (route.auth && !user) {
          router.redirect("/");
        } else {
          page = route.component;
        }
      },
    );
  });

  router.start();
  //====================================================//

  import { onMount } from "svelte";
  import { userstatus } from "./ustore.js";

  import router from "page";
  import routes from "./routes";

  import { fly } from "svelte/transition";

  onMount(async () => {});
</script>

<svelte:head>
  <!-- //https://iconoir.com/docs/introduction -->
  <!-- // How to use it in your code example : you need to add class="iconoir-name-of-icon"  <i class="iconoir-cart"></i> -->
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/gh/lucaburgio/iconoir@main/css/iconoir.css" />

  <title>appsvelte</title>
</svelte:head>

<main>
  <!-- <div style="border : solid blue;">
Hello  <i class="iconoir-cart"></i>
</div> -->

  <!-- to make the nav sticky, add the sticky class to the div wrapping around the nav -->
  <div class="sticky">
    {#if $userstatus == true}
      <nav class="navbar">
        <div>
          <a href="/">Home</a>
          <a href="/sse">SSE</a>
          <a href="/socket">Socket</a>
        </div>
      </nav>
    {:else}
      <nav class="navbar">
        <div>
          <a href="/">Home</a>
          <a href="/sse">SSE</a>
          <a href="/socket">Socket</a>
        </div>
      </nav>
    {/if}
  </div>
  <div class=container>
    <div class=centered-component>
      <svelte:component this={page} {params} />
    </div>

  </div>
  
</main>

<style>
  div.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    
  }

  main {
    text-align: center;
    font-size: 1.5rem;
    padding: 0.1em;
    max-width: 100%;
    margin: 0px;
    background-color: #c1c1c1;
    height:100%;
    font-family: 'regularText', sans-serif;
  }
  .container {
    display: flex;
    justify-content: center;
    background-color: #c1c1c1;
  }

  .centered-component {
    text-align: center;
    background-color: #c1c1c1;
  }

    .navbar {
      background: linear-gradient(to right, #364062,#513e78,#553b68,#485e75,#364062);
      overflow: hidden;
      padding: 20px 20px;
      border-radius: 30px;
      margin-left:100px;
      margin-right:100px;
      margin-top:10px;
      margin-bottom:10px;
      text-align: center;
      
    }

    /* Style the links inside the navigation bar */
    .navbar a {
      color: #f2f2f2;
      text-align: center;
      padding: 10px 14px;
      text-decoration: none;
      font-size: 1.5rem;
      margin:10px;
    }

    /* Change the color of links on hover */
    .navbar a:hover {
      background-color: #ddd;
      color: black;
      border-radius: 10px; 
      transition: background-color 0.3s ease; 
    }

   /*  --------end of min-width------------ */

  /* ------max-width 540px small screen------------------*/
  @media (max-width: 600px) {
    main {
      padding-bottom: 10%;
      text-align: center;
      max-width: 100%;
      
    }

    .navbar {
      background: linear-gradient(to right, #364062,#513e78,#553b68,#485e75,#364062);
      overflow: hidden;
      padding: 20px 20px;
      border-radius: 30px;
      margin:10px;
      text-align: center;
    }

    /* Style the links inside the navigation bar */
    .navbar a {
     
      color: #f2f2f2;
      text-align: center;
      padding: 5px 5px;
      text-decoration: none;
      font-size: 1rem;
    }

    /* Change the color of links on hover */
    .navbar a:hover {
      background-color: #ddd;
      color: black;
    }
  } /*end of max-width 540px small screen*/
</style>
