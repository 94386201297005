<script>
  // every page needs to have this script to use page and redirect
  // you can put this in a script file and imported in every page.
  import router from "page";
  let page;
  let params;

  router(
    // Set the params variable to the context.
    // We use this on the component initialisation

    (ctx, next) => {
      params = { ...ctx.params };
      next();
    },

    // Check if auth is valid. If so, set the page to the component
    // otherwise redirect to login.

    () => {
      if (route.auth && !user) {
        router.redirect("/login");
      } else {
        page = route.component;
      }
    },
  );
  router.start();

  // end of page config

  let streamingnow = "";
  let datafromserver;
  let documentkey = "";
  let documentemail = "";
  $: showordernotifications = false;
  $: showsingleorderdetails = false;
  // details is to display the popup screen with single order details after notification played and owner press the new order button
  $: details = false;

  $: allorders = [];

  $: apiurl = "";
  //$: console.log("apiurl log :", apiurl)

  import { userstatus, devprod } from "../ustore.js";
  import { onMount } from "svelte";

  import { apidev, apiprod } from "../../ignorethisfolder/url1.js";

  onMount(async () => {
    if ($devprod === "dev") {
      //console.log(">>>>> devprod log :", $devprod)
      //console.log(">>>>>>>>>>log for dev :", apidev)
      apiurl = apidev;
    } else {
      //console.log("<<<<< devprod log :", $devprod)
      //console.log("<<<<<<<<<<<log for prod :", apiprod)
      apiurl = apiprod;
    }
  }); // end onMount

  // https://stackoverflow.com/questions/65998542/how-should-i-use-svelte-reactivity-with-dom-getelementbyid
  let audiofile;

  //const source = new EventSource('/api/v1/sse');

  //  const source =new EventSource ("/api/v1/sse")
  const source = new EventSource("https://aicheck.me/api/v1/sse");

  source.addEventListener("message", (message) => {
    // you can send the data that you need from server or parse the stringified object from server

    // if there is a message from the server. Play the audio
    if (message) {
      audiofile.play();
      //gettodaysorders()
    }

    //order id data from server
    console.log({ message });
    streamingnow = message.data;

    console.log("--- :", message.data.fullDucoment);
    // stringified server data will be parsed front and dealt with that data
    datafromserver = JSON.parse(streamingnow);
    //console.log("datafromserver log :", datafromserver)
    documentkey = datafromserver.documentKey._id;
    documentemail = datafromserver.fullDocument.email;
  });

  function shownotifications() {
    showordernotifications = true;
  }

  // this will show order details when notification music starts.
  function viewsingleorder() {
    console.log("documentkey log inside viewsingleorder : ", documentkey);
    console.log("email log inside viewsingleorder : ", documentemail);
    audiofile.pause();
    details = true;
  }
</script>
<svelte:head>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
    integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w=="
    crossorigin="anonymous" />
  <title></title>
</svelte:head>

<main>
  <br /><br />
  <div>
    <button class="button-orders" on:click={shownotifications}
      >Show Orders Notifications</button>
  </div>

  <div class="main-container">
    {#if showordernotifications}
      <p class="text-orders">
        Notification sound will play when an order is received. <br />Use the player
        below to control the volume and sound of the notification.
      </p>
      <p class="text-orders">To stop the sound after receiving an order, press the pause button.</p>
      <audio class="audio" id="playit" bind:this={audiofile} controls>
        <source src="bensound-dance.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

      {#if documentkey}
        <h2 class="header-orders">Order Id :  {documentkey}</h2>
        <h2 class="header-orders">Email : {documentemail}</h2>
        <span
          ><button class="button-orders-view" on:keydown={viewsingleorder} on:click={viewsingleorder}
            >View Order</button
          ></span>
      {/if}
    {/if}
  </div>
  
  <hr />
</main>
<style>
  main{
    background-color: #c1c1c1;
    
  }
  .main-container{
    max-width: 100vw;
    background-color: #c1c1c1;
  }
  .button-orders{
    border-radius: 30px;
    padding: 15px;
    border:0px;
    color:white;
    background-color:#464e83;
  }
  .button-orders-view{
    border-radius: 30px;
    padding: 15px;
    border:0px;
    color:white;
    background-color:#464e83;
  }
  .button-orders:hover{
    transform: scale(1.1);
    transition-duration: 100ms;
    color:#464e83;
    background-color:white;
  }
  .button-orders-view:hover{
    transform: scale(1.3);
    transition-duration: 100ms;
    color:#464e83;
    background-color:white;
  }
  .text-orders{
    color:black;
    margin:0px;
    margin-bottom: 20px;
  }
  .header-orders{
    color:black;
    margin-top:50px;
    font-size:2.5rem;
    margin-right:0px;
    margin-left:0px;
    margin-bottom: 50px;
  }
  .audio{
    padding: 15px;
  }
  @media (max-width: 600px) {
      .text-orders{
      margin:25px;
    }
      .button-orders{
        width:90vw;
    }
    .button-orders-view{
        width:90vw;
    }
    .button-orders-view:hover{
      transform: scale(1);
    }
    .audio{
    width:80vw;
    }
    .button-orders:hover{
        transform: scale(1);
    }
    .header-orders{
    font-size:1.9rem;
  }
  }
</style>
