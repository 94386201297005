<script>
  import { onMount } from "svelte";
  import { userstatus, devprod } from "../ustore.js";
  import { fly, fade } from "svelte/transition";

  import { apidev, apiprod } from "../../ignorethisfolder/url1.js";

  //import the component with script context="module" to share the appurl accros the app's other components
  import Testingmodule, { getappurl } from "./testingmodule.svelte";

  $: testing = getappurl();
  $: console.log("testing log :", testing);

  onMount(async () => {
    //  let response = await fetch("https://aicheck.me:3000/")
    // //let response = await fetch("http://localhost:3000/")
    //     let getserverresult = await response.json()
    //     console.log("server get response :", getserverresult)

    let appurl = getappurl();
    console.log("url log in onMount inside index.svelte :", appurl);
    //let response = await fetch("/api/v1/")
    let response = await fetch(appurl);
    let serverresponse = await response.json();
    console.log("server response from api v1 / log :", serverresponse);
  });

  async function loginform(event) {
    let email = event.target.email.value;

    console.log("getemail value :", email);

    let url = getappurl() + "/post";
    console.log("url for ip :", url);
    let response = await fetch(url, {
      method: "POST",
      origin: "http://localhost:5000",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    }); //fetch

    let serverresponse = await response.json();
    console.log("serverresponse from ip handle :", serverresponse);
  } //end loginform
</script>

<svelte:head>
  <!-- //https://iconoir.com/docs/introduction -->
  <!-- // How to use it in your code example : you need to add class="iconoir-name-of-icon"  <i class="iconoir-cart"></i> -->
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/gh/lucaburgio/iconoir@main/css/iconoir.css" />
  <title>SSE-Socket</title>
  <meta
    name="description"
    content="Svelte App Example With API Backend In Rust. Svelte reactivity, stores and props with component composition." />
</svelte:head>

<main>
  <!-- <h1> import testingModule component and inspect the value of appurl variable</h1> -->

  <!-- <div>

 <p>P : aaaAAA222 E : b@b.com</p>
 <p>P : aaaAAA333 E :edge@edge.com</p>
 <p>P : aaaAAA333 E :opera@opera.com</p>
 <p>p : AAbbcc333 E :brave@brave.com   </p>

  </div> -->
  <br /><br /><br /><br /><br />
  <span style="color:black;">This is a test site. Nothing to see here.</span>
  <br /><br /><br />
  <form id="aform" on:submit|preventDefault={loginform}>
    <!-- <div>    
    <label for="username">username</label>
    <input required="" type="username" id="username" />
  </div> -->

    <div>
      
      <input class="email-field" placeholder="Enter your email" required="" type="email" id="email" />
      <button class="button-login"type="submit">Login</button>
    </div>
  </form>
</main>

<style>
  /* .schedule{
  width : 60%;
  height : 10%;
  border : solid blue 5px;
  } */
  .email-field{
    margin: 10px 10px;
    border-radius: 30px;
    width: 280px;
    padding: 15px;
  }
  .button-login{
    border-radius: 30px;
    padding: 15px;
    border:0px;
    color:white;
    background-color:#464e83;
    width:100px;
  }
  .button-login:hover{
    transform: scale(1.1);
    transition-duration: 100ms;
    color:black;
    background-color:white;
  }
  @media (max-width: 600px) {
    .email-field{
      width: 90vw;
    }
    .button-login{
      width:90vw;
    }
    .button-login:hover{
    transform: scale(1);
    }
  }
</style>
