<script>
  import io from "socket.io-client";

  let documentkey = "";
  let documentemail = "";
  let showordernotifications = false;

  $: showordernotifications = false;
  $: details = false;

  let audiofile;

  function shownotifications() {
    showordernotifications = true;

    console.log("connecting socket api/v1");

    let socket = io("https://aicheck.me", {
      path: "/api/v1/socket",
    });

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("buenas", (d) => console.log({d}))

    socket.on("notification", (data) => {
      if (data) {
        audiofile.play();
      }

      console.log({ data });

      documentemail = data.event.fullDocument.email;
      documentkey = data.event.fullDocument._id;
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });
  }

  // this will show order details when notification music starts.
  function viewsingleorder() {
    console.log("documentkey log inside viewsingleorder : ", documentkey);
    console.log("email log inside viewsingleorder : ", documentemail);
    audiofile.pause();
    details = true;
  }
</script>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/gh/lucaburgio/iconoir@main/css/iconoir.css" />
  <title>Socket</title>
  <meta
    name="description"
    content="Svelte App Example With API Backend In Rust. Svelte reactivity, stores and props with component composition." />
</svelte:head>

<main>
  <br /><br />
  <div>
    <button class="button-orders" on:click={shownotifications}
      >Show Orders Notifications</button>
  </div>

  <div class="main-container">
    {#if showordernotifications}
      <p class="text-orders">
        Notification sound will play when an order is received.<br /> Use the player
        below to control the volume and sound of the notification.
      </p>
      <p class="text-orders">To stop the sound after receiving an order, press the pause button.</p>
      <audio class="audio" id="playit" bind:this={audiofile} controls>
        <source src="bensound-dance.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

      {#if documentkey}
        <h2 class="header-orders">Order Id : {documentkey}</h2>
        <h2 class="header-orders">Email : {documentemail}</h2>
        <span
          ><button class="button-orders-view" on:keydown={viewsingleorder} on:click={viewsingleorder}
            >View Order</button
          ></span>
      {/if}
    {/if}
  </div>
  <hr />
</main>
<style>
  .main-container{
    max-width: 100vw;
    background-color: #c1c1c1;
  }
  .button-orders{
    border-radius: 30px;
    padding: 15px;
    border:0px;
    color:white;
    background-color:#464e83;
  }
  .button-orders-view{
    border-radius: 30px;
    padding: 15px;
    border:0px;
    color:white;
    background-color:#464e83;
  }
  .button-orders:hover{
    transform: scale(1.1);
    transition-duration: 100ms;
    color:black;
    background-color:white;
  }
  .button-orders-view:hover{
    transform: scale(1.3);
    transition-duration: 100ms;
    color:#464e83;
    background-color:white;
  }
  .text-orders{
    color:black;
    margin:0px;
    margin-bottom: 20px;
  }
  .header-orders{
    color:black;
    margin-top:50px;
    font-size:2.5rem;
    margin-right:0px;
    margin-left:0px;
    margin-bottom: 50px;
  }
  .audio{
    padding: 15px;
  }
  @media (max-width: 600px) {
      .text-orders{
      margin:25px;
    }
      .button-orders{
        width:90vw;
    }
    .button-orders-view{
        width:90vw;
    }
    .button-orders-view:hover{
      transform: scale(1);
    }
    .audio{
    width:80vw;
    }
    .button-orders:hover{
        transform: scale(1);
    }
    .header-orders{
    font-size:1.9rem;
  }
  }
</style>
