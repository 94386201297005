// to share state among different component, we use script context="module"
// export a function with whatever you want to share with other components
// import the component and use the function in other components without creating state or use store.

<script context="module">
     // changing devorprod to dev or production to change the url in any fetch handle throughout the app
    let devorprod = "ndev" 

    // return this from a function with the correct url address
    export let appurl = ""

    // export a function
    export function getappurl (){

        if (devorprod === "dev"){
            appurl = "http://localhost:3000"    
        }else{
            appurl = "https://aicheck.me/api/v1"
        }

        //return the value you wish to share with other components
        return appurl
    }

</script>

